.loginContainer {
	display: grid;
	width: 100vw;
	height: 100vh;
	place-items: center;
	background: linear-gradient(90deg, #1078B6 0%, #245694 100%);
}

.loginHeader {
	width: 100%;
	display: flex;
	justify-content: center;
}

.loginFields {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 2rem;
}

.loginLoader {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	gap: 2rem;
}
