.link {
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	background: none;
	border: none;
	color: var(--brand-primary);
	font: var(--link);
	padding: 0;
}

.unselectable {
	color: #444;
	cursor: default;
	pointer-events: none;
}

.container {
	width: 100%;
}

.list {
	display: flex;
	flex-direction: column;
}

.tiles {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	gap: 3rem;
}
