.step {
    transition: transform 0.15s ease-in;
    transform: scale(1);
    cursor: pointer;
    user-select: none;
}

.step:hover::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, 0.15) !important;
}
