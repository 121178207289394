.dateInput {
	position: relative;
	width: 100%;
	min-width: 200px;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */

	display: block;
	padding: var(--input-padding);
	font: var(--input-text);
	color: var(--text-secondary);
	min-width: 160px;
	outline: none;
	-webkit-appearance: none;

	min-height: 4rem;
	background: transparent;
	box-sizing: border-box;
	border-radius: var(--input-radius);
	border: var(--input-border);
	background: var(--background-secondary);
	transition: background 183ms ease, border 183ms ease;
	will-change: background, border;
}
