.button {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-self: flex-start;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  margin: 0px;
  outline: 0px;
  cursor: pointer;
  border-radius: var(--button-radius);
  transition: background 183ms ease 0s, box-shadow 183ms ease 0s;
  will-change: background, box-shadow;
  padding: 0;
  box-shadow: var(--button-shadow);
  border: var(--button-border);
  user-select: none;
}

.small {
  padding: var(--button-padding-s);
  font: var(--button-text-s);
}

.medium {
  padding: var(--button-padding-m);
  font: var(--button-text-m);
}

.full {
  padding: var(--button-padding-l);
  font: var(--button-text-l);
  width: 100%;
}

.outline {
  background: transparent;
  color: var(--brand-primary);
  border-color: var(--brand-primary);
}

.plain {
  color: var(--text-secondary);
  border: none;
  background: transparent;
}

.primary {
  color: white;
  background: var(--brand-primary);
}

.colorless {
  color: var(--text-secondary);
  border-color: var(--brand-secondary-shade);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: grey;
  border-color: grey;
  color: white;
}

.contentWrapper {
  display: flex;
  column-gap: 1rem;
  color: inherit;
  align-items: center;
  font-weight: 600;
}
