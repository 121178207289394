.loadingElement {
  position: relative;
  height: 100%;
}

.loadingElement::before {
  content: '';
  display: inline-block;
  position: absolute;
  opacity: 1;
  border: 2px solid transparent;
  border-left-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  top: 50%;
  left: 50%;

  width: var(--size);
  height: var(--size);
  margin-top: calc(var(--size) / 2 * -1);
  margin-left: calc(var(--size) / 2 * -1);
}

@keyframes loading {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.loadingElement.loading::before {
  animation: loading 1s linear infinite;
  border-color: #0093d0;
  border-left-color: transparent;
}