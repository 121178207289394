.labelledInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 600;
  padding-left: 2px;
}

.input {
  padding: 10px;
  text-indent: 5px;
  border: 2px solid #c2c2c2;
  border-radius: 5px;
  outline: none;
}
input[type="date"].input  {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background-color: white;
  border: 2px solid #c2c2c2;
  color: #000000;
}

.input:focus, .input:focus-visible {
  border-color: #0093d0;
}
