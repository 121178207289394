.bodyShow {
  display: block;
}

.bodyHide {
  display: none;
}

.imageCard {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  max-width: 170px;
}

.imageCardRow {
  flex-direction: row;
  flex-wrap:  wrap;
  gap: 1rem;
}

.sizeInfoToggle {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.sizeInfo {
  display: flex;
  flex-direction: row;
}